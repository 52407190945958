



















































































































































































































































































































































































































































import LazyHydrate from 'vue-lazy-hydration';
import {
  SfSkeleton,
  SkeletonLoader,
  SfAlert,
  SfButton,
  SfLink,
  SfGallery,
  SfHeading,
  SfLoader,
  SfPrice,
  SfRating,
  SfImage,
  SfBar,
  SfSelect,
  SfReview,
  SfIcon,
  HTMLContent,
  ProductInfo,
  ProductExpandItem,
  ProductDescription,
  ProductCare,
  TogasAddToWishlist,
  SizeIcon
} from '~/components';

import {
  defineComponent,
  ref,
  computed,
  PropType,
  toRef,
  onMounted,
  useRoute,
  useRouter,
  useContext,
  watch,
} from '@nuxtjs/composition-api';

import {
  getName as getProductName,
  getProductSku,
  getPrice as getProductPrice,
  getHeadDescription,
  getIsCustomGallery,
  getDetails,
  getCare,
  getIsSensotex,
  getIsInStock,
  hasCategoryCollectionLink,
  getCategoryCollectionLabel,
  getCategoryCollectionUrl,
  getIsSaleable,
  getIsHasSizeTable,
  getIsNotifyEnable,
  getIsPresentation,
  getIsPromo,
  getPromoDetailsImage,
} from '~/modules/catalog/product/getters/productGetters';

import reviewGetters, {
  getTotalReviews,
  getReviewId,
  getReviewAuthor,
  getReviewDate,
  getReviewMessage,
  getReviewRating,
} from '~/modules/review/getters/reviewGetters';

import {
  useProduct,
  useReview,
  UseReviewAddReviewParams,
  useUiNotification,
  useUiState,
  useUser,
  useWishlist,
  useCart,
  useProductGallery
} from '~/composables';

import VueScrollTo from 'vue-scrollto';

import Vue from 'vue';
import VueTippy from 'vue-tippy/dist/vue-tippy.esm';

Vue.use(VueTippy);
Vue.component('tippy');

import { Product } from '~/modules/catalog/product/types';

import { usePageStore } from '~/stores/page';

import {
  IN_STOCK_COLOR,
  IN_STOCK_ICON,
  IN_STOCK_TEXT,
  OUT_OF_STOCK_COLOR,
  OUT_OF_STOCK_TEXT,
  OUT_STOCK_ICON,
  ADD_TO_CART_BTN_TEXT,
  ProductPromoTypes,
} from '~/modules/catalog/product/constants';

export default defineComponent({
  name: 'ConfigurableProduct',
  components: {
    HTMLContent,
    LazyHydrate,
    SfSkeleton,
    SkeletonLoader,
    SfButton,
    SfLink,
    SfGallery,
    SfHeading,
    SfLoader,
    SfPrice,
    SfRating,
    SfAlert,
    SfImage,
    SfBar,
    SfSelect,
    SfReview,
    SfIcon,
    TogasAddToWishlist,
    ProductExpandItem,
    ProductInfo,
    ProductDescription,
    ProductCare,
    SizeIcon,
    ProductAddReviewForm: () => import('~/modules/catalog/product/components/ProductAddReviewForm.vue'),
    UpsellProducts: () => import('~/modules/catalog/product/components/UpsellProducts.vue'),
    ProductGallery: () => import(/* webpackPrefetch: true */ '~/modules/catalog/product/components/ProductGallery.vue'),
    ProductGalleryModal: () => import('~/modules/catalog/product/components/ProductGalleryModal.vue'),
    ProductLanding: () => import('~/modules/catalog/product/components/ProductLanding.vue'),
  },
  transition: 'fade',
  props: {
    product: {
      type: [Object, null] as PropType<Product>,
      default: null,
    },
    upsells: {
      type: [Array, null],
      default: null,
    },
    isFetching: {
      type: Boolean,
      default: true,
    },
    isPriceLoading: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['notify-me', 'fetch-product'],
  setup(props, { emit }) {
    const product = toRef(props, 'product');
    const { app } = useContext();
    const { routeData } = usePageStore();
    const { isAuthenticated } = useUser();
    const { getProductPath } = useProduct();
    const { send: sendNotification } = useUiNotification();
    const route = useRoute();
    const router = useRouter();

    const {
      addItem,
      error: cartError,
      loading: isCartLoading,
      canAddToCart,
    } = useCart();

    const {
      productGallery,
      productVideoGallery,
      imageSizes,
    } = useProductGallery(product);

    const {
      addOrRemoveItem,
      isInWishlist
    } = useWishlist();

    const {
      toggleLoginModal,
      toggleNotifyMeSidebar,
      toggleProductSizeSidebar
    } = useUiState();

    //product
    const quantity = ref(1);
    const productPrice = computed(() => getProductPrice(props.product).regular);
    const productSpecialPrice = computed(() => getProductPrice(props.product).special);
    const totalReviews = computed(() => getTotalReviews(props.product));
    const addToCartBtnText = computed(() => canAddToCart ? app.i18n.t(ADD_TO_CART_BTN_TEXT) : app.i18n.t(OUT_OF_STOCK_TEXT));//TODO будет лди аут оф сток или нотифай всегда
    const addToCartError = computed(() => cartError.value?.addItem?.message);
    const productColors = computed(() => props.product.color_swatches || []);
    const productColorsLength = computed(() => productColors.value.length);
    const addItemToWishlist = async ({ product }) => {
      if (isAuthenticated.value) {
        await addOrRemoveItem({ product });
      } else {
        await router.push(app.localeRoute({ name: 'customer-login-register', query: { redirect: route.value.path } }));
        sendNotification({
          id: Symbol('need_to_login'),
          message: app.i18n.tc('Need to login message'),
          type: 'warning',
          icon: 'check',
          persist: false,
        });
      }
    };
    const handleNotifyMe = (sku) => {
      emit('notify-me', sku);
    };

    // const ready = computed(() => Boolean(
    //   product !== null &&
    //   stockStatusText.value &&
    //   selectedOption.value
    // ));

    const ready = computed(() => Boolean(product !== null && stockStatusText.value));

    //stock data
    const stockStatusText = computed(() => {
      return canAddToCart && getIsSaleable(props.product, configurableOptions.value, activeSize.value)
        ? app.i18n.t(IN_STOCK_TEXT)
        : app.i18n.t(OUT_OF_STOCK_TEXT);
    });
    const stockStatusIcon = computed(() => {
      return canAddToCart && getIsSaleable(props.product, configurableOptions.value, activeSize.value)
        ? IN_STOCK_ICON
        : OUT_STOCK_ICON;
    });
    const stockStatusIconColor = computed(() => {
      return canAddToCart && getIsSaleable(props.product, configurableOptions.value, activeSize.value)
        ? IN_STOCK_COLOR
        : OUT_OF_STOCK_COLOR;
    });
    const stockStatusClass = computed(() => {
      return canAddToCart && getIsSaleable(props.product, configurableOptions.value, activeSize.value)
        ? 'product__stock-status-in'
        : 'product__stock-status-out';
    });
    const isInStock = computed(() => getIsInStock(props.product));

    //gallery
    const isGalleryModalOpen = ref(false);
    const activeImage = ref(0);
    const galleryModalActiveTab = ref('image');
    const handleModalGallery = (type = 'image', index = 0) => {
      galleryModalActiveTab.value = type ? type : 'image';
      if (type === 'image' && productVideoGallery.value.video_content && index >= 2) {
        activeImage.value = index - 1;
      } else {
        activeImage.value = index;
      }
      isGalleryModalOpen.value = true;
    };
    const handleModalGalleryTab = (status) => {
      if (galleryModalActiveTab.value === status) {
        return;
      }
      if (status === 'image') {
        galleryModalActiveTab.value = status;
        activeImage.value = 0;
        return;
      }

      galleryModalActiveTab.value = status;
      activeImage.value = 0;
    };

    //reviews
    const { search: searchReviews, addReview } = useReview();
    const isReviewsLoading = ref(true);
    const reviews = ref(null);
    const getReviewsSearchQuery = () => ({
      filter: {
        sku: {
          eq: routeData.sku,
        },
      },
    });
    const fetchReviews = async (query = getReviewsSearchQuery()) => {
      const productReviews = await searchReviews(query);
      const baseReviews = Array.isArray(productReviews)
        ? productReviews[0]
        : productReviews;

      reviews.value = reviewGetters.getItems(baseReviews);
      isReviewsLoading.value = false;
    };
    const successAddReview = async (reviewData: UseReviewAddReviewParams) => {
      await addReview(reviewData);
    };

    //conf
    const activeSize = ref('');
    const productConfiguration = computed(() => {
      return Object.fromEntries(
        Object.entries(route.value.query).filter(([key]) => key !== 'fbclid')
      );
    });
    const configurableOptions = computed(() => props.product?.configurable_options ?? []);
    const selectedOption = computed(() => configurableOptions.value[0]?.values.find((size) => size.uid === activeSize.value));
    const getBaseSearchQuery = () => ({
      filter: {
        sku: {
          eq: routeData.sku,
        },
      },
      configurations: Object.entries(productConfiguration.value)
        .filter(([key]) => key !== 'fbclid')
        .map(config => config[1]) as string[]
    });
    const updateProductConfiguration = (uid: string, value: string) => {
      if (productConfiguration.value[uid] === value) return;
      activeSize.value = value;
      productConfiguration.value[uid] = value;
      const routerData = router.resolve({
        path: ``, //TODO: needed ??
        query: {
          ...productConfiguration.value,
        },
      });
      window.history.pushState({}, null, routerData.href);

      emit('fetch-product', { query: getBaseSearchQuery() });
    };

    //promo landings
    const isPromoLanding = computed(() => getIsPromo(props.product));
    const promoDetailsImage = computed(() => {
      const imagePath = getPromoDetailsImage(props.product);

      return imagePath ? imagePath : null;
    });
    const isPalladaLanding = computed(() => props.product.product_promo_block_type === ProductPromoTypes.BEDLINE_PALLADA);
    const top = ref(null);
    const handleScrollTop = () => {
      VueScrollTo.scrollTo(top.value, 500, { offset: -200 });
    };

    //color tooltip
    const getTippySettings = (color) => {
      return {
        distance: '10px',
        placement: 'top-start',
        flip: false,
        theme: 'product-color',
        arrow: true,
        arrowType: 'sharp',
        content: `${color.name} ${color.swatch_color.toLowerCase()}`
      };
    };

    watch(
      configurableOptions,
      () => {
        if (typeof window !== 'undefined') {
          getIsSaleable(props.product, configurableOptions.value, activeSize.value); //TODO need??
          const size = Object.values(productConfiguration.value).join();
          if (size) {
            activeSize.value = size;
          } else {
            if (configurableOptions.value.length > 0 && configurableOptions.value[0].values.length) {
              updateProductConfiguration(
                configurableOptions.value[0].attribute_uid,
                configurableOptions.value[0].values[0].uid
              );
            }
          }
        }
      }, { immediate: true }
    );

    onMounted(() => {
      fetchReviews();
    });

    // UpsellProducts
    const hasUpsellProducts = computed(() => props.upsells);

    return {
      //user
      isAuthenticated,
      isInWishlist: computed(() => isInWishlist({ product: props.product })),
      //user actions
      addItem,
      addItemToWishlist,
      canAddToCart,
      addToCartError,
      isCartLoading,
      toggleLoginModal,
      toggleNotifyMeSidebar,
      toggleProductSizeSidebar,
      //product info
      quantity,
      getProductPath,
      getProductName,
      getProductSku,
      getHeadDescription,
      getDetails,
      getCare,
      getIsSensotex,
      getIsSaleable,
      getIsNotifyEnable,
      getIsHasSizeTable,
      getIsPresentation,
      productPrice,
      productSpecialPrice,
      stockStatusText,
      stockStatusIconColor,
      stockStatusIcon,
      stockStatusClass,
      isInStock,
      addToCartBtnText,
      productColors,
      productColorsLength,
      handleNotifyMe,
      ready,
      //promo landing
      isPromoLanding,
      isPalladaLanding,
      getPromoDetailsImage,
      promoDetailsImage,
      top,
      handleScrollTop,
      //gallery
      getIsCustomGallery,
      productGallery,
      productVideoGallery,
      imageSizes,
      handleModalGallery,
      handleModalGalleryTab,
      isGalleryModalOpen,
      activeImage,
      galleryModalActiveTab,
      //reviews
      isReviewsLoading,
      totalReviews,
      reviews,
      fetchReviews,
      getReviewId,
      getReviewAuthor,
      getReviewDate,
      getReviewMessage,
      getReviewRating,
      hasCategoryCollectionLink,
      getCategoryCollectionLabel,
      getCategoryCollectionUrl,
      successAddReview,
      //conf
      configurableOptions,
      selectedOption,
      updateProductConfiguration,
      productConfiguration,
      activeSize,
      //upsell
      hasUpsellProducts,
      //color tooltip
      getTippySettings
    };
  },
});
